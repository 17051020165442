
import { defineComponent, onMounted, ref } from "vue";
import PrescriberProjectComponent from "@/components/prescribers/PrescriberProject.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "update-project",
  components: { PrescriberProjectComponent },
  setup: function () {
    const router = useRouter();
    let projectId = ref<number>(+router.currentRoute.value.params.projectId);
    return { projectId };
  },
});
